import React, { useRef, useState } from 'react'
import style from './index.module.less'
import {
    ID_1_Desktop,
    ID_1_mobile,
    ID_2_Desktop,
    ID_2_mobile,
    ID_3_Desktop,
    ID_3_mobile,
    ID_4_Desktop,
    ID_4_mobile,
    ID_5_Desktop,
    ID_5_mobile,
    ID_6_Desktop,
    ID_6_mobile,
    ID_7_Desktop,
    ID_7_mobile
} from '@/images/onepro/index'
import useBigScreen from "@/hooks/bigScreen"
import { gsap } from 'gsap'
import classNames from 'classnames'

const DetailImg = () => {
    const ifPc = useBigScreen(768)
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
        containerRef.current.style.cursor = "grabbing";
    };

    const handleMouseLeaveOrUp = () => {
        setIsDragging(false);
        containerRef.current.style.cursor = "grab";
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = x - startX;

        // 使用 GSAP 动画滚动
        gsap.to(containerRef.current, {
            scrollLeft: scrollLeft - walk,
            duration: 0.3,
            ease: "power2.out",
        });
    };
    return (
        <div className={style.box}>
            {ifPc ?
                <div className={style.content}
                    ref={containerRef}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseLeaveOrUp}
                    onMouseUp={handleMouseLeaveOrUp}
                    onMouseMove={handleMouseMove}
                >
                    <div className={style.img} style={{ backgroundImage: `url(${ID_1_Desktop})`, aspectRatio: '2/3' }}></div>
                    <div className={style.img} style={{ backgroundImage: `url(${ID_2_Desktop})`, aspectRatio: '4/3' }}></div>
                    <div className={style.imgBox}>
                        <div className={style.img} style={{ backgroundImage: `url(${ID_3_Desktop})`, aspectRatio: '1/1' }}></div>
                        <div className={style.img} style={{ backgroundImage: `url(${ID_4_Desktop})`, aspectRatio: '1/1' }}></div>
                    </div>
                    <div className={style.img} style={{ backgroundImage: `url(${ID_5_Desktop})`, aspectRatio: '2/3' }}></div>
                    <div className={style.img} style={{ backgroundImage: `url(${ID_6_Desktop})`, aspectRatio: '4/3' }}></div>
                    <div className={style.img} style={{ backgroundImage: `url(${ID_7_Desktop})`, aspectRatio: '2/3' }}></div>

                </div> :
                <div className={style.content}>
                    <div className={style.mBox}>
                        <div className={style.mBoxTop} style={{ backgroundImage: `url(${ID_1_mobile})`, aspectRatio: '33/20' }}></div>
                        <div className={style.mBoxBottom} >
                            <div className={style.mBoxBottomItem}
                                style={{ backgroundImage: `url(${ID_2_mobile})`, aspectRatio: '16/15' }}
                            ></div>
                            <div className={style.mBoxBottomItem}
                                style={{ backgroundImage: `url(${ID_3_mobile})`, aspectRatio: '16/15' }}
                            ></div>
                        </div>
                    </div>
                    <div className={style.img} style={{ backgroundImage: `url(${ID_4_mobile})`, aspectRatio: '22/36' }}></div>
                    <div className={style.mBox} >
                        <div className={style.mBoxBottom} >
                            <div className={style.mBoxBottomItem}
                                style={{ backgroundImage: `url(${ID_5_mobile})`, aspectRatio: '16/15' }}
                            ></div>
                            <div className={style.mBoxBottomItem}
                                style={{ backgroundImage: `url(${ID_6_mobile})`, aspectRatio: '16/15' }}
                            ></div>
                        </div>
                        <div className={style.mBoxTop} style={{ backgroundImage: `url(${ID_7_mobile})`, aspectRatio: '33/20' }}></div>

                    </div>
                </div>}

        </div>
    )
}
export default DetailImg