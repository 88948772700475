import React ,{ useState } from 'react'
import style from './index.module.less'
import { useIntl } from "react-intl"
import { one_sound_logo, Bose,
    sound_bose_bg2,
    sound_bose_bg3, } from '@/images/one/index'
import useBigScreen from "@/hooks/bigScreen"

const SoundBose = ()=>{
    const intl = useIntl()
    const ifPc=useBigScreen(768)
    const intro_arr = [
         {
            img: sound_bose_bg2,
            title: intl.formatMessage({ id: "one_sound_intro2_title" }),
            desc: intl.formatMessage({ id: "one_sound_intro2_desc" }),
        }, {
            img: sound_bose_bg3,
            title: intl.formatMessage({ id: "one_sound_intro3_title" }),
            desc: intl.formatMessage({ id: "one_sound_intro3_desc" }),
        }
    ]
    const [ifHover,setIfHover] = useState(false)

    return(
        <>
           <div className={style.box}>
            <div className={style.black} style={{ backgroundImage: `url(${Bose})` }}>
                <div className={style.blackTitle}>
                    {intl.formatMessage({ id: "one_sound_title" })}
                </div>
                <div className={style.blackDesc}>
                    {intl.formatMessage({ id: "one_sound_desc" })}
                </div>
                <img src={one_sound_logo} className={style.blackImg} />
            </div>
            {ifPc?  <div className={style.sound}>
                {intro_arr.map((item,index)=>(
                    <div className={style.soundItem} key={`onepro_sound_${index}`} style={{backgroundImage:`url(${item.img})`,
                    flex: !ifHover && index == 0 ? 2 : 1 
                }}
                    onMouseEnter={()=>setIfHover(true)}
                    onMouseLeave={()=>setIfHover(false)}
                    >
                        <div className={style.soundItemTitle}>
                            {item.title}
                        </div>
                    
                        <div className={style.soundItemDesc}
                        style={{opacity: !ifHover && index == 0 ? 1:0,
                            display: !ifHover && index == 0 ?'block':"none"
                        }}

                        dangerouslySetInnerHTML={{ __html: `${item.desc}` }}
                        >
                        
                        </div>
                    </div>
                ))}
            </div>:<div className={style.msound}>
                {intro_arr.map((item,index)=>(
                    <div className={style.msoundItem} key={`onepro_msound_${index}`}
                    style={{backgroundImage:`url(${item.img})`}}
                    >
                            <div className={style.msoundItemTitle}>{item.title}</div>
                            <div className={style.msoundItemDesc} dangerouslySetInnerHTML={{ __html:item.desc }}></div>
                    </div>
                ))}
                </div>}
          
           
        </div>
        </>
    )
}

export default SoundBose