import React , {useEffect,useRef} from "react";
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from 'react-intl'
import {
    adjustable_1,
    adjustable_2
}from '@/images/onepro/index'
import Onepro_adjustable_glass from '@/images/SVG/onepro_adjustable_glass.svg'
const Adjustable = () => {
    gsap.registerPlugin(ScrollTrigger)

    const intl = useIntl()
    const lenseRef = useRef()
    const introRef = useRef()
    const mode1_Ref = useRef()
    const mode2_Ref = useRef()
    const mode3_Ref = useRef()
    const mask_Ref = useRef()

    useEffect(()=>{
        if (!!lenseRef.current) {
            gsap.registerPlugin(ScrollTrigger)
            const tl = gsap.timeline({
              scrollTrigger: {
                trigger:lenseRef.current,
                start: "top top",
                end: "bottom top",
                scrub: true, // 滚动同步动画
                pin: true,
                // markers: true,
              }
            })
            tl.to(introRef.current, {
              duration: 1,
              xPercent: -150,
              opacity: 0.4,
              ease: "power1.out",
            })
              .to(mode1_Ref.current, {
                duration: 1,
                x: 0,
                opacity: 1,
                ease: "power1.out",
              }, '<')
              .to(mask_Ref.current, {
                duration: 1,
                opacity: 0.5,
                ease: "power1.out",
              })
              .to(mode1_Ref.current, {
                duration: 1,
                opacity: 0,
                ease: "power1.out",
              }, '<')
              .to(mode2_Ref.current, {
                duration: 1,
                opacity: 1,
                ease: "power1.out",
              }, '<')
              .to(mask_Ref.current, {
                duration: 1,
                opacity: 0.2,
                ease: "power1.out",
              })
              .to(mode2_Ref.current, {
                duration: 1,
                opacity: 0,
                ease: "power1.out",
              }, '<')
              .to(mode3_Ref.current, {
                duration: 1,
                opacity: 1,
                ease: "power1.out",
              }, '<')
          }
    },[])

    return (
        <div className={style.box} ref={lenseRef}>
            {/* x-gina-lens */}
            <div className={style.lensBg}>
            {/* x-gina-lens-bg */}
                <img
                    src={adjustable_2}
                    alt="scene"
                    loading="lazy"
                    width="auto"
                    height="auto"
                />
                <div className={style.mask} ref={mask_Ref}></div>
            </div>
            <img
                src={adjustable_1}
                className={style.woman}
                alt="A woman sitting on a sofa"
                loading="lazy"
                width="auto"
                height="auto"
            />
            {/* "x-gina-lens-woman" */}
            {/* x-gina-lens-content */}
            <div className={style.lensContent}>
                <div className={style.intro} ref={introRef}>
                    <Onepro_adjustable_glass />
                    <h2>{intl.formatMessage({ id: "onepro_adjustable_title"})}</h2>
                    <p>{intl.formatMessage({ id: "onepro_adjustable_desc"})}</p>
                </div>
                <h3 className={style.mode_1} ref={mode1_Ref}>{intl.formatMessage({ id: "onepro_adjustable_type1"})}</h3>
                <h3 ref={mode2_Ref}>{intl.formatMessage({ id: "onepro_adjustable_type2"})}</h3>
                <h3  ref={mode3_Ref}>{intl.formatMessage({ id: "onepro_adjustable_type3"})}</h3>
            </div>
        </div>
    )

}

export default Adjustable