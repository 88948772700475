import React ,{useEffect}from 'react'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from 'classnames'
import {OSD} from '@/images/onepro/index'
import { useIntl } from 'react-intl';

const Ease = () => {
    gsap.registerPlugin(ScrollTrigger)
    const intl = useIntl()

    useEffect(()=>{
        if (document.querySelector('.x-gina-osd')) {
            gsap.registerPlugin(ScrollTrigger)
            const tl = gsap.timeline({
              scrollTrigger: {
                trigger: '.x-gina-osd',
                start: "top top",
                end: "bottom top",
                scrub: true,
                pin: true,
                // markers: true,
              }
            })
            tl.from(".x-gina-osd-main", {
              width: "100%",
              height: "100%",
              borderRadius: 0,
              duration: 1,
            }).from(".x-gina-osd__content", {
              opacity: 0,
              duration: 1,
            }, '<')
          }
    },[])

    return (
        <div className={classNames('x-gina-osd',style.box)}>
            <div className={classNames("x-gina-osd-main",style.main)}>
                <img
                    src={OSD}
                    class="x-gina-osd__img"
                    loading="lazy"
                    width="auto"
                    height="auto"
                />
                    <div className={classNames("x-gina-osd__content",style.content)}>
                        <h3>{intl.formatMessage({ id: "onepro_osd_title" })}</h3>
                        <p>{intl.formatMessage({ id: "onepro_osd_desc" })}</p>
                    </div>
            </div>
        </div>
    )
}

export default Ease