import React,{useEffect,useRef} from 'react'
import style from './index.module.less'
import {GINA_FOV_video} from '@/images/onepro/index'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Fov50 = () => {
    gsap.registerPlugin(ScrollTrigger)

    const intl = useIntl()
    const fovRef = useRef()
    const fovVideoRef = useRef()
    const fovAir2ProRef = useRef()
    const fovOneProRef = useRef()
    
    useEffect(()=>{
        if (!!fovRef.current) {
            gsap.registerPlugin(ScrollTrigger)
            const tl = gsap.timeline({
              scrollTrigger: {
                trigger: fovRef.current,
                start: "top top",
                end: "bottom top",
                scrub: true,
                pin: true,
                // markers: true,
              }
            })
            tl.to(fovVideoRef.current, {
              duration: 1.5,
              clipPath: 'inset(0px 0px round 0px)',
              ease: "power1.out",
            })
              .to(fovAir2ProRef.current, {
                duration: 1,
                opacity: 0,
                ease: "power1.out",
              }, '<')
              .to(fovOneProRef.current, {
                duration: 1.5,
                opacity: 1,
                ease: "power1.out",
              }, '<')
          }
    },[])

    return (
        <div className={style.box} ref={fovRef}>{/* .x-gina-big */}
            <video autoPlay playsInline loop muted width="100%" ref={fovVideoRef}>{/* .x-gina-big-video */}
                <source src={GINA_FOV_video} type="video/mp4" />
            </video>
            <div className={style.content1}  ref={fovAir2ProRef}>   
            {/* x-gina-big-content air-2-pro */}
                <div className={style.screen}>
                    <p>133<span>"</span></p>
                    <p>46°<span>FOV</span></p>
                </div>
                <p className={style.product}>XREAL Air 2 Pro</p>
            </div>
            <div className={classNames(style.content1,style.content1OnePro)} ref={fovOneProRef}>
                  {/* x-gina-big-content  one-pro */}
                <p className={style.tips} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "onepro_fov_desc"})}` }}></p>
                <div className={style.screen}>
                    <p>171<span>"</span></p>
                    <p>57°<span>FOV</span></p>
                </div>
                <p className={style.product}>XREAL One Pro</p>
            </div>
        </div>
    )
}
export default Fov50