import React, { useState } from 'react'
import style from './index.module.less'
import { useIntl } from "react-intl"
import {
    highlight_1,
    highlight_2,
    highlight_3,
    highlight_4,
    highlight_5,
    highlight_6,
    highlight_m_1,
    highlight_m_2,
    highlight_m_3,
    highlight_m_4,
    highlight_m_5,
    highlight_m_6,
} from '@/images/onepro/index'
import classNames from 'classnames'
import useBigScreen from "@/hooks/bigScreen"

const Highlights = () => {
    const intl = useIntl()
    const ifPc = useBigScreen(768)
    const [ifHover, setIfHover] = useState(0)
    const [mShowIndex, setShowIndex] = useState(false)
    const high_arr = [
        {
            title: intl.formatMessage({ id: "onepro_highlights_content1_title" }),
            desc: intl.formatMessage({ id: "onepro_highlights_content1_desc" }),
            pcImg: highlight_1,
            mobileImg: highlight_m_1,
        },
        {
            title: intl.formatMessage({ id: "onepro_highlights_content2_title" }),
            desc: intl.formatMessage({ id: "onepro_highlights_content2_desc" }),
            pcImg: highlight_2,
            mobileImg: highlight_m_2,
        }, {
            title: intl.formatMessage({ id: "onepro_highlights_content3_title" }),
            // desc:intl.formatMessage({ id: "onepro_highlights_content3_desc" }),
            pcImg: highlight_3,
            mobileImg: highlight_m_3,
        }, {
            title: intl.formatMessage({ id: "onepro_highlights_content4_title" }),
            // desc:intl.formatMessage({ id: "onepro_highlights_content4_desc" }),
            pcImg: highlight_4,
            mobileImg: highlight_m_4,
        }, {
            title: intl.formatMessage({ id: "onepro_highlights_content5_title" }),
            // desc:intl.formatMessage({ id: "onepro_highlights_content5_desc" }),
            pcImg: highlight_5,
            mobileImg: highlight_m_5,
        }, {
            title: intl.formatMessage({ id: "onepro_highlights_content6_title" }),
            // desc:intl.formatMessage({ id: "onepro_highlights_content6_desc" }),
            pcImg: highlight_6,
            mobileImg: highlight_m_6,
        }
    ]
    return (
        <>{ifPc ? <div className={style.box}>
            <div className={style.left}>
                <div className={style.title}>
                    {intl.formatMessage({ id: "onepro_highlights_title" })}
                </div>
                <div className={style.desc}>
                    {intl.formatMessage({ id: "onepro_highlights_desc" })}
                </div>
            </div>
            <div className={style.right}>
                {high_arr.map((item, index) => (
                    <div className={classNames(style.rightItem, ifHover == index && style.rightItemShow)} key={`hightlight_item${index}`}
                        style={{ backgroundImage: index == ifHover ? `url(${item.pcImg})` : '' }}
                        onMouseEnter={() => setIfHover(index)}
                        onMouseLeave={() => setIfHover(0)}
                    >
                        <div className={style.rightItemTitle}>
                            {item.title}

                            {!!item.desc && <div className={style.rightItemDesc}>
                                {item.desc}
                            </div>}
                        </div>


                    </div>
                ))}
            </div>
        </div> : <div className={style.mbox}>
            <div className={style.mtitle}>
                {intl.formatMessage({ id: "onepro_highlights_title" })}
            </div>
            <div className={style.mdesc}>
                {intl.formatMessage({ id: "onepro_highlights_desc" })}
            </div>
            <div className={style.mHighlight}>
                {
                    high_arr.map((item, index) => (
                        <div className={style.mHighlightItem} key={`onepro_mhighlight_${index}`}
                            onClick={() => setShowIndex(index)}
                        >
                            <div className={style.mHighlightItemBox}>
                                <div className={style.mHighlightItemHead}>
                                    <div className={style.mHighlightItemTitle}>
                                        {item.title}
                                    </div>
                                    {!!item.desc&&index===mShowIndex && <div className={style.mHighlightItemDesc}

                                    >{item.desc}</div>}
                                </div>


                                <div className={style.mHighlightItemBtn}>
                                    <span className={style.add} style={{ opacity: mShowIndex === index ? 0 : 1 }}></span>
                                    <span></span>
                                </div>
                            </div>
                            { mShowIndex === index&&<div className={style.mHighlightItemImg} style={{backgroundImage:`url(${ifPc?item.pcImg:item.mobileImg})`}}>
                                
                            </div>}
                            

                        </div>
                    ))
                }
            </div>


        </div>}</>


    )
}
export default Highlights