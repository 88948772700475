import React from 'react'
import style from './index.module.less'
import useBigScreen from "@/hooks/bigScreen"

const Enhanced = ({intro,textStyle}) => {
    const ifPc = useBigScreen(768)
    return (
        <div className={style.box}>
            <div className={style.optics} style={{mixBlendMode:textStyle?'difference':'normal'}}>
                <div className={style.opticsTitle}>
                    {intro.title}
                </div>
                <div className={style.opticsDesc}>
                    {intro.desc}
                </div>
                <div className={style.opticsTip} dangerouslySetInnerHTML={{ __html: intro.tip }}>
                </div>
            </div>
            <div className={style.video}>
                {!!intro.video?<video autoPlay playsInline loop muted width="100%" >
                    <source src={intro.video} type="video/mp4" />
                </video>:<div className={style.videoImg} style={{backgroundImage:`url(${ifPc?intro.pc_img:intro.mobile_img})`}}>
                    </div>}
                
            </div>
        </div>
    )
}

export default Enhanced 