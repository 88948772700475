import React from 'react'
import style from './index.module.less'
import {
    ipd_glass,
    ipd_icon1,
    ipd_icon2,
    ipd_icon3,
} from '@/images/onepro/index'
import { useIntl } from 'react-intl'
import useBigScreen from "@/hooks/bigScreen"
import useNavigate from '@/hooks/useNavigate'

const IPD = () => {
    const intl = useIntl()
    const ifPc = useBigScreen(1000)
    const ipd_arr = [
        {
            icon: ipd_icon1,
            bg: ipd_glass,
            title: intl.formatMessage({ id: "onepro_ipd_content1_text1" }),
            desc: intl.formatMessage({ id: "onepro_ipd_content1_text2" }),
            tip: intl.formatMessage({ id: "onepro_ipd_content1_text3" }),
            link: '/ipd'
        }, {
            big: intl.formatMessage({ id: "onepro_ipd_content2_text1" })
        }, {
            icon: ipd_icon2,
            title: intl.formatMessage({ id: "onepro_ipd_content3_text1" }),
            desc: intl.formatMessage({ id: "onepro_ipd_content3_text2" }),
            tip: intl.formatMessage({ id: "onepro_ipd_content3_text3" }),
        }, {
            big: intl.formatMessage({ id: "onepro_ipd_content4_text1" })
        }, {
            icon: ipd_icon3,
            title: intl.formatMessage({ id: "onepro_ipd_content5_text1" }),
            desc: intl.formatMessage({ id: "onepro_ipd_content5_text2" }),
            tip: intl.formatMessage({ id: "onepro_ipd_content5_text3" }),
        }
    ]
    return (
        <div className={style.box}>
            <div className={style.ipd}>
                {
                    ipd_arr.map((item, index) => (
                        <div className={style.ipdItem} key={`onepro_ipd_${index}`}
                        style={{
                            backgroundColor:!!item.big?'#2E2E2F':'#202021',
                            backgroundImage:!!item.bg&&ifPc?`url(${item.bg})`:'',
                            width:!!item.bg?'calc(100% / 3 * 2)':'calc(100% / 3)',
                            aspectRatio:!!item.bg?'2/1':'1/1',
                            justifyContent:!!item.big?'flex-end':'space-between'
                        }}
                        >
                           
                            {!!item.big&&<div className={style.big}>{item.big}
                            </div>}
                            {!!item.icon&&<img src={item.icon} />}
                            {!!item.icon&&<div className={style.ipdItemText}>
                                <div className={style.ipdItemTextTitle}>{item.title}</div>    
                                <div className={style.ipdItemTextDesc}>{item.desc}</div>   
                                 <div className={style.ipdItemTextTip}>{item.tip}</div> 
                                 {item.link&&<div className={style.ipdItemBtn}
                                    onClick={()=>useNavigate(item.link)}
                                 >
                                {intl.formatMessage({ id: "onepro_ipd_content1_btn" })}
                            </div>} 
                            </div>}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default IPD