import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import style from './index.module.less'
import TipMark from '@/images/SVG/tip_mark.svg'
import White_Window from '@/images/icon/white_window.svg'
import { upgrade_model } from '@/images/onepro/index'
import DetailImg from './../DetailImg/index'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";

const BiggestUpgrade = () => {
    const intl = useIntl()
    gsap.registerPlugin(ScrollTrigger)
    const boxRef = useRef()
    const topRef = useRef()

    useEffect(() => {
        if (!!boxRef.current) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: boxRef.current,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                    pin: true,
                    // markers: true,
                }
            })
            tl.to(topRef.current, {
                backgroundColor: 'transparent',
                // duration: 1,
                ease: "power2.out"
            })
            tl.to(topRef.current, {
                top:'-100px',
                opacity:0,
                // duration: 1,
                ease: "power2.out"
            })
        }


    }, [])

    return (
        <div className={style.videoBox}>
            <video autoPlay playsInline loop muted width="100%" >
                <source src={upgrade_model} type="video/mp4" />
            </video>

            <div className={style.box} ref={boxRef}>
                <div className={style.top} ref={topRef}>
                    <div className={style.title}  dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "onepro_upgrade_title" })}` }}>
                       
                    </div>
                    <div className={style.desc}>
                        {intl.formatMessage({ id: "onepro_upgrade_desc" })}
                        <div className={style.tip}>
                            <TipMark />
                            <div className={style.tipWindow} style={{ backgroundImage: `url(${White_Window})` }}>
                                <div className={style.tipWindowTitle}>
                                    {intl.formatMessage({ id: "one_part1_text3" })}
                                </div>
                                <div className={style.tipWindowLink}
                                
                                onClick={()=>window.open('https://us.shop.xreal.com/blogs/behind-the-innovation/what-is-a-3dof-vs-6dof-in-ar-glasses')}>
                                    {intl.formatMessage({ id: "learn_more" })}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
            <DetailImg />

        </div>

    )
}
export default BiggestUpgrade